/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { VaultAbi, VaultAbiInterface } from "../VaultAbi";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "int128",
        name: "wid",
        type: "int128",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "addr",
        type: "uint256",
      },
    ],
    name: "Deposit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bool",
        name: "active",
        type: "bool",
      },
    ],
    name: "EmergencyShutdown",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint128",
        name: "amount",
        type: "uint128",
      },
      {
        indexed: false,
        internalType: "int8",
        name: "wid",
        type: "int8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "user",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "creditor",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "recipient",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "tokenAmount",
        type: "uint128",
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "tonAmount",
        type: "uint128",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "swapType",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "slippageNumerator",
        type: "uint128",
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "slippageDenominator",
        type: "uint128",
      },
      {
        indexed: false,
        internalType: "bytes1",
        name: "separator",
        type: "bytes1",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "level3",
        type: "bytes",
      },
    ],
    name: "FactoryDeposit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "payloadId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "InstantWithdrawal",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "governance",
        type: "address",
      },
    ],
    name: "NewPendingGovernance",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "PendingWithdrawalCancel",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "payloadId",
        type: "bytes32",
      },
    ],
    name: "PendingWithdrawalCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "PendingWithdrawalFill",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum IVault.ApproveStatus",
        name: "approveStatus",
        type: "uint8",
      },
    ],
    name: "PendingWithdrawalUpdateApproveStatus",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bounty",
        type: "uint256",
      },
    ],
    name: "PendingWithdrawalUpdateBounty",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "requestedAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "redeemedAmount",
        type: "uint256",
      },
    ],
    name: "PendingWithdrawalWithdraw",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "strategy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "debtRatio",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minDebtPerHarvest",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxDebtPerHarvest",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "performanceFee",
        type: "uint256",
      },
    ],
    name: "StrategyAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "strategy",
        type: "address",
      },
    ],
    name: "StrategyAddedToQueue",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "oldVersion",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newVersion",
        type: "address",
      },
    ],
    name: "StrategyMigrated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "strategy",
        type: "address",
      },
    ],
    name: "StrategyRemovedFromQueue",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "strategy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "gain",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "loss",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "debtPaid",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalGain",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalSkim",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalLoss",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalDebt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "debtAdded",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "debtRatio",
        type: "uint256",
      },
    ],
    name: "StrategyReported",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "strategy",
        type: "address",
      },
    ],
    name: "StrategyRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "strategy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "debtRatio",
        type: "uint256",
      },
    ],
    name: "StrategyUpdateDebtRatio",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "strategy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxDebtPerHarvest",
        type: "uint256",
      },
    ],
    name: "StrategyUpdateMaxDebtPerHarvest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "strategy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minDebtPerHarvest",
        type: "uint256",
      },
    ],
    name: "StrategyUpdateMinDebtPerHarvest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "strategy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "performanceFee",
        type: "uint256",
      },
    ],
    name: "StrategyUpdatePerformanceFee",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
      {
        indexed: false,
        internalType: "int128",
        name: "wid",
        type: "int128",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "addr",
        type: "uint256",
      },
    ],
    name: "StrategyUpdateRewards",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "bridge",
        type: "address",
      },
    ],
    name: "UpdateBridge",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "int128",
        name: "wid",
        type: "int128",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "addr",
        type: "uint256",
      },
    ],
    name: "UpdateConfiguration",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "fee",
        type: "uint256",
      },
    ],
    name: "UpdateDepositFee",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "depositLimit",
        type: "uint256",
      },
    ],
    name: "UpdateDepositLimit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "governance",
        type: "address",
      },
    ],
    name: "UpdateGovernance",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "guardian",
        type: "address",
      },
    ],
    name: "UpdateGuardian",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "management",
        type: "address",
      },
    ],
    name: "UpdateManagement",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "managenentFee",
        type: "uint256",
      },
    ],
    name: "UpdateManagementFee",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "performanceFee",
        type: "uint256",
      },
    ],
    name: "UpdatePerformanceFee",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "int128",
        name: "wid",
        type: "int128",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "addr",
        type: "uint256",
      },
    ],
    name: "UpdateRewards",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "targetDecimals",
        type: "uint256",
      },
    ],
    name: "UpdateTargetDecimals",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "undeclaredWithdrawLimit",
        type: "uint256",
      },
    ],
    name: "UpdateUndeclaredWithdrawLimit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "fee",
        type: "uint256",
      },
    ],
    name: "UpdateWithdrawFee",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "withdrawGuardian",
        type: "address",
      },
    ],
    name: "UpdateWithdrawGuardian",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "withdrawLimitPerPeriod",
        type: "uint256",
      },
    ],
    name: "UpdateWithdrawLimitPerPeriod",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address[20]",
        name: "queue",
        type: "address[20]",
      },
    ],
    name: "UpdateWithdrawalQueue",
    type: "event",
  },
  {
    inputs: [],
    name: "acceptGovernance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_debtRatio",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minDebtPerHarvest",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxDebtPerHarvest",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_performanceFee",
        type: "uint256",
      },
    ],
    name: "addStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "apiVersion",
    outputs: [
      {
        internalType: "string",
        name: "api_version",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "availableDepositLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bridge",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "recipient",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "bounty",
        type: "uint256",
      },
    ],
    name: "cancelPendingWithdrawal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "configuration",
    outputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "convertFromTargetDecimals",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "convertToTargetDecimals",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "creditAvailable",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
    ],
    name: "creditAvailable",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
    ],
    name: "debtOutstanding",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "debtOutstanding",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "debtRatio",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "eventData",
        type: "bytes",
      },
    ],
    name: "decodeWithdrawalEventData",
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "int128",
                name: "wid",
                type: "int128",
              },
              {
                internalType: "uint256",
                name: "addr",
                type: "uint256",
              },
            ],
            internalType: "struct IEverscale.EverscaleAddress",
            name: "sender",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint32",
            name: "chainId",
            type: "uint32",
          },
        ],
        internalType: "struct IVaultBasic.WithdrawalParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "recipient",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
        ],
        internalType: "struct IVault.PendingWithdrawalId",
        name: "pendingWithdrawalId",
        type: "tuple",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "recipient",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "recipient",
        type: "tuple",
      },
      {
        internalType: "uint256[]",
        name: "amount",
        type: "uint256[]",
      },
      {
        components: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
        ],
        internalType: "struct IVault.PendingWithdrawalId[]",
        name: "pendingWithdrawalId",
        type: "tuple[]",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "depositFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "depositLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint128",
        name: "amount",
        type: "uint128",
      },
      {
        internalType: "int8",
        name: "wid",
        type: "int8",
      },
      {
        internalType: "uint256",
        name: "user",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "creditor",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "recipient",
        type: "uint256",
      },
      {
        internalType: "uint128",
        name: "tokenAmount",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "tonAmount",
        type: "uint128",
      },
      {
        internalType: "uint8",
        name: "swapType",
        type: "uint8",
      },
      {
        internalType: "uint128",
        name: "slippageNumerator",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "slippageDenominator",
        type: "uint128",
      },
      {
        internalType: "bytes",
        name: "level3",
        type: "bytes",
      },
    ],
    name: "depositToFactory",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "emergencyShutdown",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
    ],
    name: "expectedReturn",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
        ],
        internalType: "struct IVault.PendingWithdrawalId",
        name: "pendingWithdrawalId",
        type: "tuple",
      },
    ],
    name: "forceWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
        ],
        internalType: "struct IVault.PendingWithdrawalId[]",
        name: "pendingWithdrawalId",
        type: "tuple[]",
      },
    ],
    name: "forceWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "governance",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "guardian",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
      {
        internalType: "address",
        name: "_bridge",
        type: "address",
      },
      {
        internalType: "address",
        name: "_governance",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_targetDecimals",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "_rewards",
        type: "tuple",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "lastReport",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lockedProfit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lockedProfitDegradation",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "management",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "managementFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldVersion",
        type: "address",
      },
      {
        internalType: "address",
        name: "newVersion",
        type: "address",
      },
    ],
    name: "migrateStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "pendingWithdrawals",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "bounty",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
          {
            internalType: "enum IVault.ApproveStatus",
            name: "approveStatus",
            type: "uint8",
          },
        ],
        internalType: "struct IVault.PendingWithdrawalParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "pendingWithdrawalsPerUser",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingWithdrawalsTotal",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "performanceFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "gain",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "loss",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_debtPayment",
        type: "uint256",
      },
    ],
    name: "report",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revokeStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
    ],
    name: "revokeStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "rewards",
    outputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
      {
        internalType: "bytes[]",
        name: "signatures",
        type: "bytes[]",
      },
      {
        internalType: "uint256",
        name: "bounty",
        type: "uint256",
      },
    ],
    name: "saveWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
      {
        internalType: "bytes[]",
        name: "signatures",
        type: "bytes[]",
      },
    ],
    name: "saveWithdraw",
    outputs: [
      {
        internalType: "bool",
        name: "instantWithdrawal",
        type: "bool",
      },
      {
        components: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
        ],
        internalType: "struct IVault.PendingWithdrawalId",
        name: "pendingWithdrawalId",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "_configuration",
        type: "tuple",
      },
    ],
    name: "setConfiguration",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_depositFee",
        type: "uint256",
      },
    ],
    name: "setDepositFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "limit",
        type: "uint256",
      },
    ],
    name: "setDepositLimit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "active",
        type: "bool",
      },
    ],
    name: "setEmergencyShutdown",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_governance",
        type: "address",
      },
    ],
    name: "setGovernance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_guardian",
        type: "address",
      },
    ],
    name: "setGuardian",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "degradation",
        type: "uint256",
      },
    ],
    name: "setLockedProfitDegradation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_management",
        type: "address",
      },
    ],
    name: "setManagement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "fee",
        type: "uint256",
      },
    ],
    name: "setManagementFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
        ],
        internalType: "struct IVault.PendingWithdrawalId[]",
        name: "pendingWithdrawalId",
        type: "tuple[]",
      },
      {
        internalType: "enum IVault.ApproveStatus[]",
        name: "approveStatus",
        type: "uint8[]",
      },
    ],
    name: "setPendingWithdrawalApprove",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
        ],
        internalType: "struct IVault.PendingWithdrawalId",
        name: "pendingWithdrawalId",
        type: "tuple",
      },
      {
        internalType: "enum IVault.ApproveStatus",
        name: "approveStatus",
        type: "uint8",
      },
    ],
    name: "setPendingWithdrawalApprove",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "bounty",
        type: "uint256",
      },
    ],
    name: "setPendingWithdrawalBounty",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "fee",
        type: "uint256",
      },
    ],
    name: "setPerformanceFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "_rewards",
        type: "tuple",
      },
    ],
    name: "setRewards",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
      {
        components: [
          {
            internalType: "int128",
            name: "wid",
            type: "int128",
          },
          {
            internalType: "uint256",
            name: "addr",
            type: "uint256",
          },
        ],
        internalType: "struct IEverscale.EverscaleAddress",
        name: "_rewards",
        type: "tuple",
      },
    ],
    name: "setStrategyRewards",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_undeclaredWithdrawLimit",
        type: "uint256",
      },
    ],
    name: "setUndeclaredWithdrawLimit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_withdrawFee",
        type: "uint256",
      },
    ],
    name: "setWithdrawFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_withdrawGuardian",
        type: "address",
      },
    ],
    name: "setWithdrawGuardian",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_withdrawLimitPerPeriod",
        type: "uint256",
      },
    ],
    name: "setWithdrawLimitPerPeriod",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[20]",
        name: "queue",
        type: "address[20]",
      },
    ],
    name: "setWithdrawalQueue",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
    ],
    name: "skim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
    ],
    name: "strategies",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "performanceFee",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "activation",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "debtRatio",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "minDebtPerHarvest",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxDebtPerHarvest",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastReport",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalDebt",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalGain",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalSkim",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalLoss",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "rewardsManager",
            type: "address",
          },
          {
            components: [
              {
                internalType: "int128",
                name: "wid",
                type: "int128",
              },
              {
                internalType: "uint256",
                name: "addr",
                type: "uint256",
              },
            ],
            internalType: "struct IEverscale.EverscaleAddress",
            name: "rewards",
            type: "tuple",
          },
        ],
        internalType: "struct IVault.StrategyParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
    ],
    name: "sweep",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "targetDecimals",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "token",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenDecimals",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalAssets",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalDebt",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "undeclaredWithdrawLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_debtRatio",
        type: "uint256",
      },
    ],
    name: "updateStrategyDebtRatio",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "maxDebtPerHarvest",
        type: "uint256",
      },
    ],
    name: "updateStrategyMaxDebtPerHarvest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "minDebtPerHarvest",
        type: "uint256",
      },
    ],
    name: "updateStrategyMinDebtPerHarvest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "strategyId",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_performanceFee",
        type: "uint256",
      },
    ],
    name: "updateStrategyPerformanceFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amountRequested",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "maxLoss",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "bounty",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [
      {
        internalType: "uint256",
        name: "amountAdjusted",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawGuardian",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawLimitPerPeriod",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    name: "withdrawalIds",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "withdrawalPeriodId",
        type: "uint256",
      },
    ],
    name: "withdrawalPeriods",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "total",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "considered",
            type: "uint256",
          },
        ],
        internalType: "struct IVault.WithdrawalPeriodParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawalQueue",
    outputs: [
      {
        internalType: "address[20]",
        name: "",
        type: "address[20]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class VaultAbi__factory {
  static readonly abi = _abi;
  static createInterface(): VaultAbiInterface {
    return new utils.Interface(_abi) as VaultAbiInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): VaultAbi {
    return new Contract(address, _abi, signerOrProvider) as VaultAbi;
  }
}
